@media screen and (min-width: 1700px) {
}

@media screen and (max-width: 1570px) {
}

@media screen and (max-width: 1350px) {
}

@media screen and (max-width: 1250px) {
  .MuiInputBase-input {
    font-size: 0.95rem !important;
  }
}

@media only screen and (max-width: 900px) {
  .btn {
    font-size: $font-size-md - 2 !important;
    padding: 10px 15px !important;
    &.short {
      padding: 4px 16px !important;
      font-size: $font-size-md - 4 !important;
    }
  }

  .badge {
    position: initial !important;
    bottom: initial !important;
  }
  .MuiInputBase-input {
    font-size: 0.9rem !important;
  }

  h2.typography {
    font-size: 1rem !important;
  }
  .typography {
    font-size: 0.95rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .toolbar {
    bottom: -17% !important;
  }
}

@media only screen and (max-width: 472px) and (min-width: 316px) {
  .MuiInputBase-input {
    font-size: 0.85rem !important;
  }
  legend > span {
    font-size: 0.9rem !important;
  }
  .MuiFormLabel-root {
    font-size: 0.9rem !important;
  }
}

@media screen and (max-width: 316px) {
}

@media screen and (max-width: 450px) {
  .margin {
    margin: 0 20px;
  }
  .typography.xsm {
    font-size: 0.6rem !important;
  }
  .typography {
    font-size: 0.9rem !important;
  }
}

@media screen and (max-width: 1700px) {
  .cameraOff {
    font-size: 2.5rem !important;
  }
}

@media screen and (max-width: 380px) {
}

/* Portrait */

@media screen and (orientation: portrait) {
  /* Portrait styles */
  @media screen and (max-width: 900px) {
  }

  @media screen and (max-width: 450px) {
  }
}

/* Landscape */

@media screen and (orientation: landscape) {
  /* Landscape styles */
  @media screen and (max-width: 900px) {
  }
}
