* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all $transition;

  :focus-visible {
    outline: none;
  }
}

a {
  cursor: pointer;
  text-decoration: none !important;
  transition: all $transition linear;
}

.cursor {
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }
}

.hover {
  filter: brightness(1);

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(1.2);
  }
}

.no-hover {
  filter: brightness(1) !important;
  background-color: initial !important;
}

.fullscreen {
  height: 100%;
  width: 100%;
}

.img {
  width: 100%;
}

body,
html,
#root {
  height: 100%;
}

.sitemap {
  .header {
    background-color: white;
    text-align: center;
    border-radius: 10px;
    padding: 5px 0;
    margin-bottom: 50px;

    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 25px;
      left: 50%;
      border-left: 2px solid rgb(123, 123, 123);
      bottom: -25px;
      z-index: 1;
    }

    .typography {
      color: rgb(41, 41, 41) !important;
    }
  }

  .items_container {
    display: flex;
    gap: 40px;
    justify-content: center;
  }

  & .item {
    .category {
      background-color: white;
    }

    li {
      background-color: white;
    }
  }

  & .item:last-child {
    &:before {
      border-top: unset !important;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
    height: fit-content;

    &:before {
      content: "";
      position: absolute;
      width: 120%;
      height: 100%;
      left: 50%;
      border-top: 2px solid rgb(123 123 123);
      border-left: 2px solid rgb(123 123 123);
      top: -25px;
      z-index: -1;
    }

    .category {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 200px;
      height: 200px;
      border-radius: 25px;
      text-align: center;
      filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.75)) saturate(0.8);

      svg {
        color: black !important;
        width: 80px;
        height: 80px;
      }

      .title {
        font-family: $font-bold !important;
        font-size: 24px !important;
        color: black !important;
      }

      .description {
        font-family: $font-medium !important;
        font-size: 14px !important;
        color: black !important;
      }
    }

    .links {
      margin-top: 25px;

      li {
        margin: 15px 0;
        width: 200px;
        min-height: 48px;
        border-radius: 10px;
        text-align: center;
        filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.75)) saturate(0.75);

        .typography {
          color: black;
        }
      }
    }
  }
}

.relative {
  position: relative;
}

a:hover {
  // color: $link-hover-color !important;
  text-decoration: none !important;

  p {
    // color: $link-hover-color !important;
    text-decoration: none !important;
  }

  filter: brightness(1.15);
}

ul {
  list-style-type: none;
}

.loader1 {
  margin: 0 auto;
  //

  $columns: 25;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100px;
  width: 100px;

  -webkit-animation: spin 15s linear infinite;
  -moz-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;

  .item {
    width: 20px;
    height: 20px;
    background-color: #43178f;
    background-size: 800% 800%;
    box-shadow: inset 0px 0px 10px 0px #292929;
    border-radius: 50%;
    transform: scale(0);
    filter: blur(0.5px);
  }

  @for $i from 1 through $columns {
    .item:nth-child(#{$i}) {
      animation: GrowAndColor ease-out infinite 1.8s;
    }
  }

  .item:nth-child(2) {
    animation-delay: 0.2s; //
  }

  .item:nth-child(3) {
    animation-delay: 0.4s; //
  }

  .item:nth-child(4) {
    animation-delay: 0.6s; //
  }

  .item:nth-child(5) {
    animation-delay: 0.8s; //
  }

  .item:nth-child(6) {
    animation-delay: 0.2s; //
  }

  .item:nth-child(7) {
    animation-delay: 0.4s; //
  }

  .item:nth-child(8) {
    animation-delay: 0.6s; //
  }

  .item:nth-child(9) {
    animation-delay: 0.8s; //
  }

  .item:nth-child(10) {
    animation-delay: 1s;
  }

  .item:nth-child(11) {
    animation-delay: 0.4s; //
  }

  .item:nth-child(12) {
    animation-delay: 0.6s; //
  }

  .item:nth-child(13) {
    animation-delay: 0.8s; //
  }

  .item:nth-child(14) {
    animation-delay: 1s;
  }

  .item:nth-child(15) {
    animation-delay: 1.2s;
  }

  .item:nth-child(16) {
    animation-delay: 0.6s; //
  }

  .item:nth-child(17) {
    animation-delay: 0.8s; //
  }

  .item:nth-child(18) {
    animation-delay: 1s; //
  }

  .item:nth-child(19) {
    animation-delay: 1.2s; //
  }

  .item:nth-child(20) {
    animation-delay: 1.4s; //
  }

  .item:nth-child(21) {
    animation-delay: 0.8s; //
  }

  .item:nth-child(22) {
    animation-delay: 1s; //
  }

  .item:nth-child(23) {
    animation-delay: 1.2s; //
  }

  .item:nth-child(24) {
    animation-delay: 1.4s; //
  }

  .item:nth-child(25) {
    animation-delay: 1.6s; //
  }

  @keyframes GrowAndColor {
    from {
      transform: scale(0);
      background-color: #43178f;
    }

    50% {
      transform: scale(1);
      background-color: #782aff;
    }

    to {
      transform: scale(0);
      background-color: #43178f;
    }
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.center {
  align-items: center;
  justify-content: center;
}

.flex.items-center {
  align-items: center;
}

.flex.content-center {
  justify-content: center;
}

.font {
  &.bold {
    font-family: $font-bold !important;
  }

  &.semibold {
    font-family: $font-semibold !important;
  }

  &.initial {
    text-transform: initial !important;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.typography {
  font-family: $font-medium !important;
  font-size: $font-size-md;
  line-height: 1.2 !important;

  &.bold {
    font-family: $font-bold !important;
  }

  &.semibold {
    font-family: $font-semibold !important;
  }

  &.initial {
    text-transform: initial !important;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.color {
  &.primary {
    color: $primary-color !important;
  }

  &.secondary {
    color: $secondary-color !important;
  }
}

.typography.h {
  font-family: $font-bold !important;
  font-size: $font-size-lg;
  color: $typography-header-color !important;
}

h1.typography {
  font-size: $font-size-xlg;
}

@media only screen and (max-width: 900px) {
  h1.typography {
    font-size: $font-size-xlg - 3px !important;
  }
}

@media only screen and (max-width: 550px) {
  h1.typography {
    font-size: $font-size-xlg - 5px !important;
  }
}

h2.typography {
  font-size: $font-size-lg;
}

@media only screen and (max-width: 900px) {
  h2.typography {
    font-size: $font-size-lg - 2px !important;
  }
}

@media only screen and (max-width: 550px) {
  h2.typography {
    font-size: $font-size-lg - 3px !important;
  }
}

h3.typography {
  font-size: $font-size-lg;
}

@media only screen and (max-width: 900px) {
  h3.typography {
    font-size: $font-size-lg - 3px !important;
  }
}

@media only screen and (max-width: 550px) {
  h3.typography {
    font-size: $font-size-lg - 4px !important;
  }
}

.typography.xsm {
  font-size: $font-size-xsm !important;
}

.typography.sm {
  font-size: $font-size-sm !important;
}

.typography.md {
  font-size: $font-size-md !important;
}

@media only screen and (max-width: 900px) {
  .typography.md {
    font-size: $font-size-md - 1px !important;
  }
}

@media only screen and (max-width: 550px) {
  .typography.md {
    font-size: $font-size-md - 2px !important;
  }
}

.typography.lg {
  font-size: $font-size-lg !important;
}

@media only screen and (max-width: 900px) {
  .typography.lg {
    font-size: $font-size-lg - 2px !important;
  }
}

@media only screen and (max-width: 550px) {
  .typography.lg {
    font-size: $font-size-lg - 3px !important;
  }
}

.typography.xlg {
  font-size: $font-size-xlg !important;
}
@media only screen and (max-width: 900px) {
  .typography.xlg {
    font-size: $font-size-xlg - 3px !important;
  }
}

@media only screen and (max-width: 550px) {
  .typography.xlg {
    font-size: $font-size-xlg - 5px !important;
  }
}

.typography.xxlg {
  font-size: $font-size-xxlg !important;
}

.typography.xxxlg {
  font-size: $font-size-xxxlg !important;
}

.font {
  &.regular {
    font-family: $font-regular !important;
  }

  &.medium {
    font-family: $font-medium !important;
  }

  &.bold {
    font-family: $font-bold !important;
  }
}

.font.Effra {
  &.Bold {
    font-family: "Effra Bold" !important;
  }

  &.Medium {
    font-family: "Effra Medium" !important;
  }

  &.Regular {
    font-family: "Effra Regular" !important;
  }
}

.font.Montserrat {
  &.Bold {
    font-family: "montserratBold" !important;
  }

  &.Medium {
    font-family: "montserratMedium" !important;
  }

  &.Regular {
    font-family: "montserratRegular" !important;
  }
}

.font.Gilroy {
  &.Bold {
    font-family: "Gilroy Bold" !important;
  }

  &.Medium {
    font-family: "Gilroy Medium" !important;
  }

  &.Regular {
    font-family: "Gilroy Regular" !important;
  }
}

.text-center {
  text-align: center;
}

.link {
  text-decoration: underline !important;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }
}

.disableFilter:hover {
  filter: unset !important;
}

.modal {
  overflow-y: scroll;
  position: absolute;
  // top: 50%;
  // left: 50%;
  // -webkit-transform: translate(-50%, -50%);
  // -moz-transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 32px;

  .wrap {
    width: 50%;
    margin: 0 auto;
  }
}

.close {
  z-index: 1000;
  color: $typography-color !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: unset !important;
  right: 1%;
  top: 1.5%;
  position: fixed !important;
}
