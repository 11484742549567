.btn {
  border: none;
  font-size: 0.9vw !important;
  font-family: $font-bold !important;
  font-weight: 100 !important;
  text-transform: capitalize !important;
  border-radius: 0.5vw !important;
  padding: 0.5vw 0.83vw !important;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: all 0.15s linear !important;
  box-shadow: unset !important;
  &.short {
    padding: 0.5vw 0.8vw !important;
    font-size: 0.75vw !important;
  }
  &.no-hover:hover {
    background-color: initial !important;
  }
}

.MuiButton-root.Mui-disabled {
  color: #ffffff80 !important;
  background-color: rgb(111, 111, 111) !important;
}

.btn:hover {
  filter: brightness(1.1);
}
.btn:active {
  filter: brightness(1.15);
}

.btn-primary {
  &.long {
    padding: 0.4vw 1.2vw !important;
  }
  &.xlong {
    padding: 12px 5vw !important;
  }
}

.btn-secondary {
  color: $white-color !important;
  background-color: transparent !important;
  border: 1px solid $white-color;
  &.round {
    border-radius: 50%;
    padding: 0.415vw !important;
  }
}

.btn-third {
  color: $white-color !important;
  background-color: $secondary-color !important;
  font-size: 0.83vw !important;
  &.round {
    border-radius: 50%;
    padding: 0.415vw !important;
  }
  &.long {
    padding: 0.32vw 1.2vw !important;
  }
  &.xlong {
    padding: 0.62vw 5vw !important;
  }
}
