$scrollbar-color: $primary-color;
$scrollbar-background: $bg-color;
$scrollbar-width: 6px;

::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: $scrollbar-background;
}

::-webkit-scrollbar:vertical {
    width: $scrollbar-width;
    background-color: $scrollbar-background;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    padding: 10px;
    background-color: $scrollbar-color;
}

.scroll{
    &::-webkit-scrollbar:horizontal {
        width: $scrollbar-width !important;
        height: $scrollbar-width !important;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        padding: 10px;
        background-color: $scrollbar-color;
    }
}

.scroll-vertical-none::-webkit-scrollbar:vertical {
    display: none;
}

.scroll-horizontal-none::-webkit-scrollbar:horizontal {
    display: none;
}