@tailwind base;
@tailwind components;
@tailwind utilities;

@import "Colors";
@import "Animations";
@import "Variables";
@import "Mixins";
@import "Scrollbar";
@import "Fonts";
@import "Global";
@import "Buttons";

//player connected users variants

// 2 users
.Screens_grid:first-child:last-child:only-child {
}

//3 users
.Screens_grid:first-child:nth-last-child(3):last-child {
  /* your styles here */
}

//4 users
.Screens_grid:first-child:nth-last-child(4):last-child {
  /* your styles here */
}

@media only screen and (max-width: 900px) {
  // 2 users
  .Screens_grid:first-child:last-child:only-child {
    display: flex;
    flex-direction: column;
  }

  //3 users
  .Screens_grid:first-child:nth-last-child(3):last-child {
    /* your styles here */
  }

  //4 users
  .Screens_grid:first-child:nth-last-child(4):last-child {
    /* your styles here */
  }
}
/////////////////////

.TextFieldUI {
  input {
    font-family: $font-medium;
  }

  textarea {
    font-family: $font-medium;
  }
}

svg {
  display: initial;
}

.menu {
  .MuiPaper-root {
    border-radius: 10px;
  }
  .MuiList-root {
    padding: 0;
  }
}

.wrap {
  padding-top: 10px !important;
  width: 99% !important;
}

.input {
  font-family: $font-medium !important;
  width: 80%;
}

.chat-text-info {
  position: inherit;
  right: 10px;
  margin-left: 15px !important;
  display: flex;
  min-width: fit-content;
  font-size: 14px !important;
  transition: color 0.35s ease-out 0s;
  bottom: 70px;
  position: absolute;
}

.chat-text-info.timedOut {
  top: 15px !important;
}

#root {
  position: relative;
}

.toolbar {
  position: absolute !important;
  background-color: unset !important;
  svg {
    width: 1.6vw;
    height: 1.6vw;
  }
}

.screen_container {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.screen {
  border-radius: 10px;
  height: 100%;
  img {
    border-radius: 10px;
  }
}

.TextFieldUI.font.medium {
  input {
    font-family: $font-medium;
  }
}
.typography.badge {
  background-color: #a0a0a0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3px;
  padding: 2px 4px 1.2px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  @media only screen and (min-width: 900px) {
    position: absolute !important;
    bottom: -6px;
  }
}
.MuiAvatar-root {
  &.borderless {
    border: 0 !important;
  }
  &.warning {
    border: 0 !important;
  }
  &.warning img {
    width: unset;
    height: unset;
  }
}

@media (min-width: 600px) {
  .MuiToolbar-root {
    min-height: 3.8vw !important;
  }
}

.inputField .MuiOutlinedInput-root {
  border-radius: 10px !important;
  border: 0px !important;
}
.inputField .MuiOutlinedInput-notchedOutline {
  border-color: unset !important;
  border: unset !important;
}

.animate.pop {
  animation-duration: 0.17s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.Screens_grid {
  display: grid;
  gap: 10px;
  width: 100%;
  height: 80vh;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  & > div {
    position: relative;
  }
}

.col-sm-3 {
  height: 80vh;
  width: 100%;
}

.RemoteVideo {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .screen > .Screens_grid + .Screens_grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(250px, calc(100% / 3 - 20px))
    );
  }
  .screen .player:only-child {
    height: 80% !important;
  }
}

.cameraOff.MuiAvatar-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  font-size: 3rem;
}

#grid {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: absolute;

  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(50px, 1fr));
  justify-content: center;
  .grid-item {
    min-width: 100%;
    min-height: 100%;
    cursor: pointer;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      outline: 1px solid #00000010;
      display: block;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

@import "Mobile";
