.ChatListElem {
}
.ChatListElem_wrap {
  display: flex;
  align-items: end;
  gap: 15px;
}

.img_wrap {
}
.img {
  width: 39px;
  height: 39px;
}

.message_wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.msg {
  p {
    font-size: 14px;
    font-family: "Gilroy Medium", Helvetica, Arial, sans-serif;
  }
}
