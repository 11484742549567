// COLORS
$primary-color: #DA0011;
$secondary-color: #1E0640;
$black-color: #000000;
$grey-color: #EBEBEB;
$white-color: #FFFFFF;

//LINKS
$link-hover-color: #fff;
$link-color: #fff;

// BACKGROUND
$bg-color: #1E0640;
$bg-gradient-first-primary: #430630;
$bg-gradient-first-secondary: #3E1935;
$bg-gradient-second-primary: #062743;
$bg-gradient-second-secondary: #01080DB9;

// TEXT
$typography-color: #FFF;
$typography-header-color: '#000000';

